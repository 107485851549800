import { createRouter, createWebHistory } from "vue-router";

//https://vuejs.org/api/#basic-usage

const routes = [
  {
    path: "/",
    name: "app.default",
    component: () => import("@/layouts/layout-base"),
    children: [
      {
        path: "",
        name: "app.page1",
        component: () => import("@/views/site/index"),
      },
      {
        path: "/produtos",
        name: "app.produtos",
        component: () => import("@/views/site/produtos"),
      },
      {
        path: "/produto/:id",
        props: true,
        name: "app.produto.visualizacao",
        component: () => import("@/views/site/produto"),
      },
      {
        path: "/page2",
        name: "app.page2",
        component: () => import("@/views/base/page2"),
      },
      {
        path: "/sobre",
        name: "app.sobre",
        component: () => import("@/views/site/sobre"),
      },
      {
        path: "/marcas",
        name: "app.marcas",
        component: () => import("@/views/site/marcas"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/components/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
